const Wordings = (i18n) => ({
  placeholder: i18n.gettext(''),
  addComponentTooltip: i18n.gettext('Agregar nuevo componente'),
  moveUpWording: 'Subir componente',
  moveDownWording: i18n.gettext('Bajar componente'),
  deleteWording: i18n.gettext('Eliminar sección'),
  tableWordings: {
    modal: {
      title: 'Agrega una tabla al texto ',
      rows: {
        label: i18n.gettext('Filas'),
        helper: 'Máximo 10 filas.',
        limit: 10,
      },
      columns: {
        label: i18n.gettext('Columnas'),
        helper: 'Máximo 10 columnas.',
        limit: 10,
      },
      actions: {
        primary: i18n.gettext('Agregar'),
        secondary: i18n.gettext('Cancelar'),
      },
      limitError: i18n.gettext('El máximo permitido es 10.'),
    },
  },
  modalLocales: {
    add_link: i18n.gettext('Agregar link'),
    cancel: i18n.gettext('Cancelar'),
    linkFieldError: i18n.gettext('Ingresa un link válido.'),
    linkFieldHelper: i18n.gettext('El link debe empezar con https:// o http://'),
    linkFieldPlaceholder: i18n.gettext('https://www.mitienda.com/productos'),
  },
  tooltips: {
    fontSize: i18n.gettext('Tamaño'),
    heading: i18n.gettext('Estilo'),
    align: i18n.gettext('Alineación del texto'),
    bold: i18n.gettext('Negrita'),
    italic: i18n.gettext('Cursiva'),
    underline: i18n.gettext('Subrayado'),
    table: i18n.gettext('Tabla'),
    unorderedList: i18n.gettext('Lista con viñetas'),
    orderedList: 'Lista numerada',
    link: i18n.gettext('Link'),
    alignOptions: {
      left: i18n.gettext('Izquierda'),
      center: i18n.gettext('Al centro'),
      right: i18n.gettext('Derecha'),
      justify: 'Justificar',
    },
  },
  comunications: {
    title: i18n.gettext('Agrega características como negrita y cursiva a tu contenido'),
    description: i18n.gettext('Texto con Formato te permite editar lo que escribes desde el mismo componente.'),
    closeButton: i18n.gettext('Cerrar'),
  },
  headingOptions: [
    {
      title: i18n.gettext('Título'),
      value: 'h2',
      class: 'title',
    },
    {
      title: 'Subtítulo',
      value: 'h3',
      class: 'subtitle',
    },
    {
      title: `${i18n.gettext('Párrafo')} 1`,
      value: 'h4',
      class: 'major-paragraph',
    },
    {
      title: `${i18n.gettext('Párrafo')} 2`,
      value: 'p',
      class: 'minor-paragraph',
    },
  ],
});

export default Wordings;
