import React from 'react';
import RichText from '@mshops-components-library/rich-text';
import Wordings from './wordings';

export default (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const wordings = Wordings(props.i18n);

  return <RichText {...props} {...wordings} editable={false} />;
};
